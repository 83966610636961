exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-elerhetoseg-tsx": () => import("./../../../src/pages/elerhetoseg.tsx" /* webpackChunkName: "component---src-pages-elerhetoseg-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-hasznos-informaciok-tsx": () => import("./../../../src/pages/hasznos-informaciok.tsx" /* webpackChunkName: "component---src-pages-hasznos-informaciok-tsx" */),
  "component---src-pages-kezdolap-tsx": () => import("./../../../src/pages/kezdolap.tsx" /* webpackChunkName: "component---src-pages-kezdolap-tsx" */),
  "component---src-pages-matraszentimre-tsx": () => import("./../../../src/pages/matraszentimre.tsx" /* webpackChunkName: "component---src-pages-matraszentimre-tsx" */),
  "component---src-pages-szabadidos-programok-tsx": () => import("./../../../src/pages/szabadidos-programok.tsx" /* webpackChunkName: "component---src-pages-szabadidos-programok-tsx" */)
}

