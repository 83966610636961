import './src/scss/main.scss';
import Swal from 'sweetalert2';

export const onServiceWorkerUpdateReady = () => {
  Swal.fire({
    title: 'A weboldal új verziója elérhető!',
    text: 'Automatikus frissítés 3 másodperc múlva...',
    icon: 'info',
    showConfirmButton: false,
    timer: 3000,
  }).then(() => {
    window.location.reload();
  });
};
