import React from 'react';

import PropTypes from 'prop-types';

import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';

const exitTimeout = 300;
const enterTimeout = 500;
const getTransitionStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
  },
  entered: {
    transition: `opacity ${enterTimeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${exitTimeout}ms ease-in-out`,
    opacity: 0,
  },
};

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props;
    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: enterTimeout,
            exit: exitTimeout,
          }}
        >
          {(status) => (
            <div
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    );
  }
}

Transition.propTypes = {
  location: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

export default Transition;
