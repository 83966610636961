import React from 'react';
import { Helmet } from 'react-helmet';
import propTypes from 'prop-types';

import Transition from './Transition';

function Layout({ children, location }) {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="title" content="Bagoly Lak" />
        <meta
          name="description"
          content="Úgy érzi megpihenne a családjával de nem tudja hol tegye? Nem kell tovább keresnie, a Bagoly Lak minden évszakban kiváló választás!"
        />
        <meta
          name="keywords"
          content="bagoly, lak, bagoly-lak, üdülés, pihenés, nyaralás, mátra, bagolyírtás, szállás, apartman"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Hungarian" />
        <link rel="apple-touch-icon" sizes="76x76" href="assets/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="assets/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="assets/favicon/favicon-16x16.png" />
        <link rel="manifest" href="assets/favicon/site.webmanifest" />
        <link rel="mask-icon" href="assets/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#00a300" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Transition location={location}>{children}</Transition>
    </>
  );
}

Layout.propTypes = {
  location: propTypes.any.isRequired,
  children: propTypes.any.isRequired,
};

export default Layout;
